// src/pages/RegisterPage.js
import React, { useState } from 'react';
import './RegisterPage.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';  // useNavigate for redirection

function RegisterPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL; // Ensure this environment variable is set correctly

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch(`${apiUrl}/register`, { // Use backticks for template literals
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const result = await response.json();
      if (result.success) {
        navigate('/'); // Redirect to the home page
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError('An error occurred during registration.');
    }
  };

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={handleSubmit}>
        <h2>Register</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default RegisterPage;
