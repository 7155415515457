import React from 'react';

function TermsAndConditions() {
    return (
        <div>
            <h1>Terms and Conditions</h1>
            <p>These are the terms and conditions of Contphone Application.</p>
        </div>
    );
}

export default TermsAndConditions;

