import React, { useState, useEffect } from 'react';
import './ImageSlider.css'; // Import the CSS file for styling

const images = [
  { src: '/media/cpImage-1.jpg', content: 'Content for Image 1' },
  { src: '/media/cpImage-2.jpg', content: 'Content for Image 2' },
  { src: '/media/cpImage-3.jpg', content: 'Content for Image 3' }
];

function ImageSlider() {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 10000); // 10 seconds per slide

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider">
      <div className="slides">
        {images.map((image, index) => (
          <div key={index} className={`slide ${current === index ? 'active' : ''}`}>
            <img src={image.src} alt={`slide-${index}`} className="slide-image" />
            <div className={`content-box ${current === index ? 'active' : ''}`}>
              <h2>{image.content}</h2>
              <p>Additional details for {image.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;
