import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if session token exists
    const cookie = document.cookie.split('; ');
    console.log('Cookies:', cookie); // Log all cookies
    //const sessionToken = cookies.find(row => row.startsWith('session_token='));
    const sessionTokenExists = document.cookie.includes('session_token');

    if (!sessionTokenExists) {
      // If no session token, redirect to login
      navigate('/');
    } else {
      console.log('Session token found:', sessionTokenExists);
      navigate('/landing');
    }
  }, [navigate]);
  



  const [activeTab, setActiveTab] = useState('form1');

  console.log('Active Tab:', activeTab);

  return (
    <div>
      <h1>Landing Page</h1>
      <div>
        <button onClick={() => setActiveTab('form1')}>Form 1</button>
        <button onClick={() => setActiveTab('form2')}>Form 2</button>
      </div>

      {activeTab === 'form1' && (
        <div style={{ backgroundColor: '#d3f9d8' }}>
          <h2>Form 1</h2>
          <form>
            {/* Form 1 content */}
          </form>
        </div>
      )}

      {activeTab === 'form2' && (
        <div style={{ backgroundColor: '#b3e5fc' }}>
          <h2>Form 2</h2>
          <form>
            {/* Form 2 content */}
          </form>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
