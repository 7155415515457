import React from 'react';
import ImageSlider from '../components/ImageSlider';
import LoginForm from '../components/LoginForm';
import './HomePage.css'; // Import the CSS file

function HomePage() {
  return (
    <div className="homepage-container">
      {/* Left section (fixed width initially) */}
      <div className="left-section">
        <ImageSlider />
        <div className="overlay-content">
          <h1>About<br />Product !!</h1>
          <p></p>
        </div>
      </div>

      {/* Right section (fixed width initially) */}
      <div className="right-section">
        <LoginForm />
      </div>
    </div>
  );
}

export default HomePage;
