import React from 'react';
import './Footer.css'; // Import your CSS file for styling

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-logo">
                    <a href="/">YourLogo</a>
                </div>
                <div className="footer-links">
                    <a href="/about">About Us</a>
                    <a href="/privacy">Privacy Policy</a>
                </div>
                <div className="footer-contact" id="contact-us">
                    <p>Contact us: info@varshesh.net</p>
                </div>
                <div className="footer-copyright">
                    <p>&copy; {new Date().getFullYear()} VIPL. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
