import React from 'react';

function AboutUs() {
    return (
        <div>
            <h1>About Us</h1>
            <p>This is the About Us page of Contphone Application.</p>
        </div>
    );
}

export default AboutUs;

