import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import TermsAndConditions from './pages/TermsAndConditions';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import RegisterPage from './pages/RegisterPage';
import LandingPage from './components/LandingPage';







function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      const cookies = document.cookie.split('; ');
      const sessionToken = cookies.find(row => row.startsWith('session_token='));
      setIsLoggedIn(!!sessionToken);
    };

    checkLoginStatus();
  }, []);

  const handleLogout = () => {
    document.cookie = 'session_token=; Max-Age=0; path=/';  // Clear the session token
    setIsLoggedIn(false);  // Update login status
  };

  return (
    <Router>
      <div className="App">
        <NavBar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy" element={<TermsAndConditions />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/landing" element={<LandingPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
