import React, { useEffect } from 'react'; // Import useEffect here
import { Link, useNavigate } from 'react-router-dom';
import './NavBar.css';  // Import your CSS file for styling

const NavBar = ({ isLoggedIn, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await fetch('/api/logout', { method: 'POST' });

      // Remove session token by expiring the cookie
      document.cookie = 'session_token=; Max-Age=0; path=/';
      onLogout();  // Call onLogout to update the parent component state
      navigate('/');  // Redirect to home page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const sessionToken = getCookie('session_token');
    if (sessionToken) {
        navigate('/landing'); // Redirect to /landing if session token exists
    } else {
        navigate('/'); // Redirect to home if session token does not exist
    }
  }, [navigate]); // Include navigate in the dependency array

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">YourLogo</Link>
        <ul className="navbar-menu">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>

          {/* Only show Profile > Logout if the user is logged in */}
          {isLoggedIn ? (
            <li className="profile-container">
              <button className="profile-button">Profile</button>
              <div className="profile-dropdown">
                <button onClick={handleLogout}>Log Out</button>
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
